/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 70번째 로그에서는 ODK 주니어 개발자 오엔 님을 모시고 주니어 개발자에 대한 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "단신"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/2/3/21120248/microsoft-teams-down-outage-certificate-issue-status"
  }, "Microsoft Teams goes down after Microsoft forgot to renew a certificate - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnbc.com/2019/12/04/slack-ceo-stewart-butterfield-microsoft-teams-has-weak-engagement.html"
  }, "Slack CEO Stewart Butterfield: Microsoft Teams has 'weak engagement'")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certificate-manager/"
  }, "AWS Certificate Manager - Amazon Web Services(AWS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/68/"
  }, "스탠다드아웃_068.log: 루비 2.7 릴리스, IRB 개선, 루비의 미래, RubyMine 등 w/ 펭귄 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/1/"
  }, "stdout_001.log: 루비 25주년, Github 장애 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20180427018200091"
  }, "아마존의 효자 AWS…\"총 매출 11%, 영업이익 73% 기여\" | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20200204099300009"
  }, "유튜브 광고 매출액 첫 공개…지난해 18조원(종합) | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/keyword/homebrew"
  }, "홈브류(Homebrew)란? | 44BITS 기술 블로그")), "\n"), "\n", React.createElement(_components.h2, null, "주니어 개발자 오엔 님"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.fastcampus.co.kr/"
  }, "패스트캠퍼스 - 커리어 성장을 위한 최고의 실무교육 아카데미")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://likelion.net/"
  }, "멋쟁이사자처럼 :: LIKELION")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/EunhyangKim2?utm_campaign=profiletracking&utm_medium=sssite&utm_source=ssslideview"
  }, "Eunhyang Kim, Developer at Odk media | SlideShare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://programmers.co.kr/competitions/95/2019-summer-coding"
  }, "2019 Summer Coding - 여름방학 스타트업 인턴 프로그램 | 프로그래머스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.arduino.cc/"
  }, "Arduino - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://odkmedia.bamboohr.com/jobs/"
  }, "ODK media Careers - BambooHR")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
